.content {
  background-color: $color-background;

  padding: 70px 12px 12px 12px;

  @include atMedium {
    padding: 94px 24px 24px 24px;
  }

  &--with-container {
    max-width: 1280px;
    margin-left: auto;
    margin-right: auto;
  }

  &--with-banner-container {
    padding: 0px 24px 24px 24px;
    max-width: 1024px;
    margin-left: auto;
    margin-right: auto;
  }

  &--with-smaller-container {
    max-width: 1024px;
    margin-left: auto;
    margin-right: auto;
  }

  &--with-smallest-container {
    max-width: 720px;
    margin-left: auto;
    margin-right: auto;
  }
}
