$color-background: #f2f2f2;
$color-white: #ffffff;
$color-background-darker: #eaeaea;
$color-primary: #221133;
$color-secondary: #221133;
$color-secondary-darker: #a30e62;
$color-pink: #a30e62;
$color-pink-lighter: #b41870;
$color-pink-lightest: #e61e8f;
$color-facebook: #3c5a99;
$color-hoverpink: #ea148d;
$color-text: #7f7f7f;
$color-counter: #404040;
$color-gray: #f2f2f2;

$color-paper-border: #c2c2c2;

$top-menu-height: 60px;
