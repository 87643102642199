.card {
  // max-width: 200px;
  padding: 0 20px;
  margin-bottom: 3.5rem;

  &__inner {
    display: flex;
    flex-flow: column;
  }
  &__image {
    display: block;
    padding: 0 20px 0 0;
  }
  &__title {
    color: black;
    font-weight: 700;
    font-size: 24px;
    margin: 1rem 0rem;
    font-size: 2.4rem;
    line-height: 1.4;
  }

  &__description {
    color: rgb(78, 78, 78);
    line-height: 1.5;
  }

  &__link {
    margin-top: 2rem;
    color: white !important;
    padding: 1rem;
    background: $color-pink;
    align-self: flex-start;
    border-radius: 5px;
    transition: background-color 0.3s ease-in-out;
    align-self: flex-start;

    &:hover {
      background: $color-pink-lighter;
    }
  }

  &__meta {
    margin-bottom: 1rem;
  }

  &__date {
    font-size: 0.8rem;
  }

  &__divider {
    margin-top: 2rem;
    border-color: rgb(243, 243, 243);
  }
}
