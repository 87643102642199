.branding {
  margin: auto 0 !important;

  &__logo {
    width: 120px !important;

    @include atLarge {
      width: 165px !important;
    }
  }
}
