.features {
  display: flex;
  flex-wrap: wrap;
}
.feature-item {
  position: relative;
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
  background-color: white;
  margin-bottom: 2rem;
  width: 90%;
  @include atSmall {
    padding: 2.5rem 48px;
  }

  @include atSmallMax {
    display: flex;
    align-items: center;
    flex-flow: column;
    padding: 1.5rem 1rem;
  }

  &__icon {
    display: block;

    @include atSmall {
    }
  }

  &__container {
    width: 33%;
    display: flex;
    align-items: stretch;
    justify-content: center;

    @include tablet {
      width: 50%;
    }

    @include atSmallMax {
      width: 100%;
    }
  }
  &__title {
    font-size: 1.3rem;
    font-weight: 300;
    margin-top: 0.7em;
    margin-bottom: 0.8em;
    text-align: center;
    font-weight: 600;

    @include atSmall {
      margin-top: 1em;
      text-align: left;
      font-size: 1.125rem;
      font-weight: 700;
    }
  }

  &__paragraph {
    text-align: center;
    line-height: 1.5;
    margin-top: 0;
    font-size: 0.9rem;
    @include atSmall {
      text-align: left;
    }
  }
}
