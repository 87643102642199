.filter-select-button {
  @include atMediumMax {
    width: 100%;
    margin-bottom: 20px !important;
  }
}

.css-g1d714-ValueContainer {
  padding-left: 0px !important;
}

.css-1okebmr-indicatorSeparator {
  display: none;
}

.css-tlfecz-indicatorContainer {
  padding: 2px !important;
}
