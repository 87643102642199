.cookieConsent {
  bottom: 82px !important;

  @include atSmall {
    bottom: 47px !important;
  }

  @include atMedium {
    bottom: 0 !important;
  }
}
