.navbar {
  background-color: #221133;
  &__inbox {
    padding: 6px !important;

    @include atMedium {
      padding: 12px !important;
    }
  }

  &__logo {
    font-size: large;
  }

  &__language-selector {
    display: none !important;

    @include atMedium {
      display: inline-block !important;
    }
  }

  &__for-employers-link {
    margin-left: 5px;
    margin-right: 5px;
  }
}
