.application-form {
  max-width: 400px;
  background-color: white;
  padding: 30px;
  text-align: left;
  max-width: 400px;
  background: rgb(255, 255, 255);
  box-shadow: rgb(0 0 0 / 10%) 0px 2px 30px;
  padding: 42px 30px 51px;
  border-radius: 5px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}
