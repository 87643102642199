.branding-login {
  padding: 25px 0;
  text-align: center;

  &__logo {
    width: 200px;

    @include atLarge {
      width: 265px;
    }
  }
}
