.intro {
  display: flex;
  width: 100%;
  // background-color: green;
  justify-content: space-between;

  @include tablet {
    flex-flow: column;
    align-items: center;
  }

  &__description {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    // background-color: yellow;
    padding-right: 1rem;

    @include tablet {
      padding-right: 0rem;
      align-items: center;
      padding-bottom: 2rem;
    }
  }

  &__headline {
    @extend .headline;
    font-size: 1.8rem;
    font-weight: 700;
    line-height: 1.4;

    @include tablet {
      text-align: center;
    }

    @include atSmallMax {
      font-size: 1.2rem;
    }
  }

  &__text {
    line-height: 1.5;
    @include tablet {
      text-align: center;
    }

    @include atSmallMax {
      font-size: 0.9rem;
    }
  }
  &__image {
    display: flex;
    justify-content: center;
    margin: 0 auto;
  }
}
