.blog-card {
  width: 100%;
  box-shadow: 3px 3px 6px 1px rgba($color: lightgray, $alpha: 0.5);
  border-radius: 1rem;
  overflow: hidden;

  &__container {
    padding: 1rem;
    @include tablet {
      width: 50%;
    }

    @include atSmallMax {
      width: 100%;
    }
  }

  &__details {
    padding: 1rem;
  }

  &__title {
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1.2;
    margin-bottom: 1rem;
  }

  &__meta {
    margin-bottom: 1rem;
  }

  &__date {
    font-size: 0.8rem;
  }

  &__description {
    line-height: 1.3;
  }

  &__link {
    margin-top: 1rem;
    padding: 0.5rem 1rem;
    color: white;
    background-color: $color-pink;
  }

  &__link-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
}
