.wrapper {
  padding-left: 18px;
  padding-right: 18px;
  max-width: 1236px;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;

  &--medium {
    max-width: 978px;
  }

  &--small {
    max-width: 770px;
  }

  .wrapper {
    padding-left: 0;
    padding-right: 0;
  }

  &--no-padding-until-medium {
    padding-left: 0px;
    padding-right: 0px;

    @include atMedium {
      padding-left: 18px;
      padding-right: 18px;
    }
  }

  &--b-margin {
    margin-bottom: 1 rem;
    @include atSmall {
      margin-bottom: 3.875rem;
    }
  }
}
