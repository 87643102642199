.headline {
  font-weight: 300;
  font-size: 2.25rem;
  color: $color-primary;

  @include atSmall {
    font-size: 2.25rem;
    font-weight: 700;
  }

  &--centered {
    text-align: center;
  }

  &--small {
    font-size: 1.2rem;
    font-weight: 700;
    letter-spacing: 2px;

    @include atSmall {
      font-size: 1.2rem;
    }
  }

  &--gray {
    color: rgb(171, 171, 171);
    letter-spacing: 3px;
    font-weight: 400;
    font-size: 15px;
  }

  &--narrow {
    max-width: 500;
    margin-left: auto;
    margin-right: auto;
  }

  &--light {
    font-weight: 100;
  }

  &--b-margin-small {
    margin-bottom: 0.5em;
  }

  &--b-margin-small {
    margin-bottom: 1.6em;
  }

  &--no-t-margin {
    margin-top: 0px;
  }

  &--black {
    color: black;
    // font-weight: 600;
  }
}
