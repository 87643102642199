.data {
  text-align: center;

  @include tablet {
    margin-top: 1rem;
  }

  &__value {
    font-size: 2.25rem;
    font-weight: 500;
  }

  &__title {
    font-weight: 200;
    color: #f8f8f8;
  }
}
