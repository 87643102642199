.main-menu {
  visibility: hidden;
  opacity: 0;
  background: rgba(255, 255, 255, 0.9);

  transition: all 0.3s ease-in-out;

  position: fixed;
  z-index: -1;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 15px;

  @include atMedium {
    padding: 0;
    position: static;
    flex-direction: row;
    background: none;
    opacity: 1;
    visibility: visible;
  }

  &--open {
    opacity: 1;
    visibility: visible;
    z-index: 100;
  }

  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    max-height: 100%;
    overflow: auto;
    width: 100%;

    @include atMedium {
      position: static;
      flex-direction: row;
      justify-content: space-evenly;
      top: unset;
      transform: translateY(0);
      max-height: unset;
      overflow: unset;
      width: unset;
    }
  }

  &__link {
    display: flex;

    margin: 25px 0;
    font-size: 2em;

    color: $color-primary;

    @include atMedium {
      color: white;
      font-size: 1em;
      margin: 25px 15px;
    }
  }

  &__close {
    position: absolute;
    right: 8px;
    top: 0px;
    width: 60px;
    height: 60px;
    font-size: 40px;
    background: transparent;
    color: $color-secondary;
    border: none;
    cursor: pointer;
    z-index: 1;

    @include atMedium {
      display: none;
    }
  }

  &__toggle {
    margin-left: 10px;
    @include atMedium {
      display: none;
    }
  }
}
