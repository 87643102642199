.footer {
  &__address {
    line-height: 1.7;
  }

  &__nav {
    padding: 0;
  }

  &__item {
    list-style: none;
    padding: 0;
    margin-bottom: 1rem;
    letter-spacing: 1px;
  }

  &__section {
    margin-top: 2rem;
  }
}
