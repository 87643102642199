.article {
  padding-top: 0rem;
  box-shadow: 0px 0px 20px 1px rgba($color: #000000, $alpha: 0.16);
  padding: 0rem;
  margin-top: -100px;
  @include tablet {
    box-shadow: none;
    margin-top: 0px;
  }
  &__banner {
    // height: 300px;
    width: 100%;
    object-fit: cover;
  }

  &__meta {
    margin-top: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__category {
    background: rgb(236, 236, 236);
    border-radius: 0.3rem;
    padding: 0.4rem;

    &:hover {
      background: $color-pink;
      color: white;
    }
  }

  &__content {
    img {
      width: 100%;
    }
  }

  &__body {
    padding: 0rem 3rem !important;
    padding-bottom: 2rem !important;

    @include atSmallMax {
      padding: 0rem 1.5rem !important;
    }
  }

  &__outer {
    display: flex;
    justify-content: space-evenly;
    @include tablet {
      flex-flow: column;
    }
  }

  &__sidebar {
    width: 20%;

    @include tablet {
      width: 100%;
      padding: 0rem 3rem;
      border-top: 1px rgba($color: #000000, $alpha: 0.16) solid;
    }

    @include atSmallMax {
      padding: 0rem 1.5rem;
    }
  }

  &__widget-list {
    display: flex;
    flex-flow: column;
    position: sticky;
    top: 0;
  }

  &__related {
    margin: 2rem 0rem;
    margin-top: 4rem;

    @include tablet {
      padding: 0rem 1.5rem;
    }
  }
}

.article-widget {
  &__title {
    font-size: 1.3rem;
    font-weight: 400;
    letter-spacing: 1px;
  }

  &__list {
    list-style: none;
    padding-left: 0;
    li {
      padding: 0.5rem 0rem;
      color: rgb(104, 104, 104);
    }
  }
}

.article-related {
  cursor: pointer;
  height: 100px;
  width: 47%;
  padding: 1rem;
  color: white;

  background-color: rgba($color: #000000, $alpha: 0.5);
  background-blend-mode: overlay;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  border-radius: 1rem;
  overflow: hidden;

  @include atSmallMax {
    width: 100%;
    margin-bottom: 0.5rem;
  }

  &__title {
    font-size: 1.1rem;
    font-weight: 600;
  }

  &__inner {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include atSmallMax {
      flex-flow: column;
      align-items: stretch;
    }
  }

  &__header {
    font-size: 2rem;
    font-weight: 500;
  }
}
