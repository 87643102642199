.row {
  // float: left;
  @include clearfix;
  // line-height: 55px;

  &--purple {
    background-color: #512e72;
    color: white;
    border-bottom: white;
  }

  &--dark-blue {
    background-color: #221133;
    color: white;
    border-bottom: white;
    padding: 3.5px 0;
  }

  &--action {
    vertical-align: baseline;
    border-bottom: 1px solid white;
    height: 50px;
    padding-top: 14px;
    font-size: 14px;
  }

  &--gutters {
    margin-right: -65px;
  }

  &--gutters-small {
    margin-right: -45px;
  }

  &--gutters-small > div {
    padding-right: 45px;
    box-sizing: border-box;
  }

  &--gutters-large {
    margin-right: -100px;
  }

  &--gutters > div {
    padding-right: 65px;
    box-sizing: border-box;
  }

  &--gutters-large > div {
    padding-right: 100px;
    box-sizing: border-box;
  }

  &--b-margin-until-medium {
    margin-bottom: 1rem;

    @include atMedium {
      margin-bottom: 1rem;
    }
  }

  &--t-padding {
    padding-top: 80px;
  }
  &__paddingTop {
    margin-top: 3%;
  }
  @include atMedium {
    &__medium-3 {
      float: left;
      width: 25%;
    }

    &__medium-4 {
      float: left;
      width: 33.33%;
    }

    &__medium-6 {
      float: left;
      width: 50%;
    }

    &__medium-4--larger {
      float: left;
      width: 37%;
    }

    &__medium-8 {
      float: left;
      width: 66.66%;
    }

    &__medium-8--smaller {
      float: left;
      width: 63%;
    }
  }

  /* begin equal height rules */
  &--equal-height-at-medium {
    @include atMedium {
      display: flex;
    }

    & > div {
      float: none;
      display: flex;
    }
  }
}
