.link {
  cursor: pointer;

  transition: color 0.2s ease-in-out;

  &:hover {
    color: $color-pink;
  }

  &--text {
    color: $color-pink;
  }

  &--category {
    text-transform: uppercase;
    text-align: center;
    color: $color-pink;
  }
}

.link-button {
  background: $color-pink;
  color: white;
  border: none;
  font-size: 1rem;
  padding: 0.5em 1em;
  border-radius: 0.2rem;
  cursor: pointer;

  @include atSmallMax {
    font-size: 0.8rem;
  }
  &:hover {
    background: $color-pink-lighter;
    color: white;
  }

  &--disabled {
    background: rgb(196, 195, 195);
    color: rgb(46, 45, 45);
    cursor: not-allowed;

    &:hover {
      background: gray;
      color: rgb(46, 45, 45);
    }
  }

  &--gap {
    margin: 0rem 0.5rem;
  }
}
