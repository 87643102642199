.overlay {
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  min-height: 100vh;
  width: 100%;
  align-items: center;
  background: white;
  animation-name: overlayOpen;
  animation-fill-mode: forwards;
  animation-duration: 0.3s;
  display: none;
  flex-flow: column;

  @include tablet {
    display: flex;
  }

  &__inner {
    animation-name: overlayOpen;
    animation-fill-mode: forwards;
    animation-duration: 0.3s;
    padding: 1rem;
    width: 100%;
  }

  &__header {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    max-width: 400px;
    padding: 1rem;
  }

  &__close {
    background: none;
    border: none;
    cursor: pointer;

    svg {
      @include tablet {
        width: 32px;
        height: 32px;
      }

      @include atSmallMax {
        width: 24px;
        height: 24px;
      }
    }
  }
}

@keyframes overlayOpen {
  from {
    opacity: 0;
    margin-right: -300%;
  }
  to {
    opacity: 1;
    margin-right: 0;
  }
}
