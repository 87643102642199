.header {
  &__logo {
    width: 165px;
    cursor: pointer;

    @include atSmallMax {
      width: 125px;
    }
  }

  &__link {
    a {
      transition: color 0.2s ease-in-out;
      &:hover {
        color: $color-pink;
      }
    }

    &--hover-lightest {
      a {
        transition: color 0.2s ease-in-out;
        &:hover {
          color: $color-pink-lightest;
        }
      }
    }
  }

  &__bottom {
    padding: 1rem 0rem;

    @include atSmallMax {
      padding: 0.9rem 0rem;
    }
  }

  &__top {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include atSmallMax {
      height: unset;
    }

    &--height-reset {
      height: auto;
      padding: 0.5rem 0rem;
    }
  }

  &__nav {
    @include atSmallMax {
      display: none;
    }
  }

  &__menu {
    display: none;

    @include atSmallMax {
      display: block;
    }
  }

  &__menu-button {
    padding: 5px;
  }

  &__mobile-menu {
    display: flex;
    flex-flow: column;
    width: 100%;
    flex: 1;
  }

  &__mobile-nav {
    display: flex;
    flex-flow: column;

    &__item {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: 0.7rem 0rem;
      border-radius: 0.5rem;
      box-shadow: 3px 3px 6px 1px rgba($color: lightgray, $alpha: 0.5);
      margin-bottom: 0.8rem;
      color: $color-pink;

      text-align: left;
      cursor: pointer;

      &--alt {
        background: $color-pink;
        color: white;
      }
    }
  }
}
