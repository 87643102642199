.section {
  padding-top: 2rem;
  padding-bottom: 2rem;
  background-color: white;

  &--bg-world {
    background-image: url("../../public/section-dots.png");
    background-color: #f6f2ed;
    background-repeat: no-repeat;
    background-position: right top;
  }

  &--big {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }

  &--gray {
    background-color: #f8f8f8;
  }

  &--dark-blue {
    background-color: $color-primary;
    color: white;
  }
}
