.reactMarkDown {
  ul {
    margin-top: 1em;
    margin-bottom: 1em;
    list-style: none;
  }
  li {
    display: list-item;
    list-style: none;
    text-align: -webkit-match-parent;
  }
}
