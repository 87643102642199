.testimonial {
  &__griditem {
    display: grid;
    grid-template-columns: auto auto auto;
    gap: 2px;

    @include atSmallMax {
      display: grid;
      grid-template-columns: auto;
      gap: 2px;
    }
  }
  &__griditem > div {
    padding: 20px;
  }
  &__paper {
    height: 400;
    width: 300;

    border-radius: "10px";
  }
}
