.slider {
  padding: 4rem 0rem;
  &__item {
    display: flex !important;
    align-items: center;
    justify-content: center;
    height: 100%;

    img {
      width: 120px !important;
      height: 120px;
      object-fit: contain;
    }
  }
}
