// @import "./mixins/padding-margin-generator";

@mixin atSmall {
  @media (min-width: 700px) {
    @content;
  }
}

@mixin atMedium {
  @media (min-width: 960px) {
    @content;
  }
}

@mixin atLarge {
  @media (min-width: 1280px) {
    @content;
  }
}

@mixin atLargeMax {
  @media (max-width: 1279px) {
    @content;
  }
}

@mixin atMediumMax {
  @media (max-width: 959px) {
    @content;
  }
}

@mixin atSmallMax {
  @media (max-width: 599px) {
    @content;
  }
}

@mixin tablet {
  @media (max-width: 800px) {
    @content;
  }
}

@mixin clearfix {
  &::after {
    content: "";
    clear: both;
    display: table;
  }
}

/* mixin for multiline */
@mixin multiLineEllipsis($lineHeight: 1.2em, $lineCount: 1, $bgColor: white) {
  overflow: hidden;
  position: relative;
  line-height: $lineHeight;
  max-height: $lineHeight * $lineCount;
  text-align: center;
  margin-right: -1em;
  padding-right: 1em;
  &:before {
    content: "...";
    position: absolute;
    right: 0;
    bottom: 0;
  }
  &:after {
    content: "";
    position: absolute;
    right: 0;
    width: 1em;
    height: 1em;
    margin-top: 0.2em;
    background: $bgColor;
  }
}
