.cookieanimation {
  animation-name: example;
  animation-duration: 1s;
  animation-timing-function: linear;
  @keyframes example {
    from {
      bottom: -20px;
    }
    to {
      bottom: 0px;
    }
  }
}
