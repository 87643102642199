.large-hero {
  position: relative;
  // border: 10px solid rgba($color: #000000, $alpha: 1);
  max-height: 700px;
  overflow: hidden;

  &__container {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding: 1rem;

    @include atSmallMax {
      padding: 1rem 0rem;
    }
  }

  &__text-content {
    padding-top: 60px;

    text-align: center;

    @include atSmallMax {
      padding-top: 30px;
    }
  }

  &__form-content {
    text-align: center;

    @include tablet {
      display: none;
    }
  }

  &__title {
    font-weight: 400;
    color: white;
    font-size: 4rem;
    margin: 0;
    text-align: start;
    max-width: 800px;

    @include tablet {
      text-align: center;
      font-size: 3rem;
    }

    @include atSmallMax {
      font-size: 2rem;
      font-weight: 300;
    }
  }

  &__cta {
    margin: 1rem 0rem;
    display: none;
    @include tablet {
      display: block;
    }
  }

  &__subtitle {
    font-weight: 300;
    color: white;
    font-size: 1.5rem;
    margin: 0;
    text-align: start;
    margin-bottom: 1rem;

    @include tablet {
      text-align: center;
      font-size: 1.35rem;
    }

    @include atSmallMax {
      font-size: 1rem;
    }
  }

  &__description {
    color: white;
    font-size: 1.1rem;
    font-weight: 100;
    text-shadow: 2px 0 rgba($color: #000000, $alpha: 0.1);
    max-width: 30rem;
    margin-left: auto;
    margin-right: auto;

    @include atSmall {
      font-size: 1.875rem;
    }
  }

  &__image {
    // display: block;
    object-fit: cover;
    object-position: left center;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -1;
  }

  &__overlay {
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    min-height: 100vh;
    width: 100%;
    justify-content: center;
    align-items: center;
    background: white;
    animation-name: overlayOpen;
    animation-fill-mode: forwards;
    animation-duration: 0.3s;
    display: none;
    flex-flow: column;

    @include tablet {
      display: flex;
    }
  }

  &__overlay-inner {
    animation-name: overlayOpen;
    animation-fill-mode: forwards;
    animation-duration: 0.3s;
    padding: 1rem;
  }

  &__overlay-header {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    max-width: 400px;
  }

  &__overlay-close {
    background: none;
    border: none;
    cursor: pointer;

    svg {
      @include tablet {
        width: 32px;
        height: 32px;
      }

      @include atSmallMax {
        width: 24px;
        height: 24px;
      }
    }
  }
}

@keyframes overlayOpen {
  from {
    opacity: 0;
    margin-right: -300%;
  }
  to {
    opacity: 1;
    margin-right: 0;
  }
}
