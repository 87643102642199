.single-package--grid-container {
  padding: 20px;
}

.single-package {
  background: -webkit-linear-gradient(top, #fff 0, #f6f6f6 100%);
  padding: 20px;
  height: 100%;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  // @include atSmallMax {
  //   height: 100% !important;
  // }

  &__title-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &--image {
      width: 50px;
    }

    &--text {
      margin: 10px !important;
    }
  }

  &--button {
    width: 100%;
    transition: all 0.5s ease 0.5s !important;
    justify-content: start !important;
    padding: 7.5px 15px !important;
    border: 1px solid #eaeaea !important;
  }

  &--button--icon {
    margin-right: 10px !important;
  }

  &--link {
    background-color: $color-primary;
    color: $color-white;
    max-width: 156px;
    width: 100%;
    transition: all 0.5s ease 0s;
    font-weight: 400;
    line-height: 38px;
    border-radius: 4px;
    cursor: pointer;
    text-decoration: none;
    text-align: center;

    &:hover {
      background-color: $color-primary;
    }
  }
}

.single-feature {
  display: flex;
  align-items: center;
  margin: 15px;

  &--icon {
    margin-right: 10px;
  }
}
