.modal {
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;

  &__paper {
    max-height: 100vh;
    overflow-y: auto;
    background-color: $color-white;
    box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 5px 8px 0px rgb(0 0 0 / 14%), 0px 1px 14px 0px rgb(0 0 0 / 12%);
    max-width: 700px;
    width: 100%;
    outline: none;
    // padding: 1rem;
  }

  &__header-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}
