@import "../base/variables";

.button {
  display: inline-block;
  background-color: $color-secondary;
  padding: 10px;
  color: white;
  text-decoration: none;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  &--pink {
    background-color: #ea148d;
    transition: background-color 0.2s ease-in;

    &:hover {
      background-color: $color-pink;
    }
  }

  &--space-vertical {
    margin: 1rem 0rem;
  }
  &__cartbutton {
    margin-left: 10px;
    width: 100%;
    margin-top: 3%;
    margin-top: 50px;
    text-transform: none;
    color: $color-white;
    background-color: $color-pink-lighter;
    &:hover {
      background-color: #ea148d;
    }
  }
}
