.pricing {
  background-color: rgba(255, 255, 255, 1);
  margin-bottom: 1rem;
  display: flex;
  flex-flow: column;
  align-items: center;
  padding: 1rem 2rem;

  @include atMedium {
    margin-bottom: 0px;
  }

  @include atSmallMax {
    width: 80%;
  }

  &__photo {
    overflow: hidden;
    display: flex;
    align-items: center;
    border: 6px solid rgba(255, 255, 255, 0.8);
  }

  &__outer {
    margin-top: 2rem;
  }

  &__headline {
    @include atSmallMax {
      font-size: 1.4rem;
    }
  }

  &__title {
    @extend .headline;
    letter-spacing: 2px;
    text-align: center;
    color: $color-primary;
    font-size: 1.4rem;
    margin-bottom: 1rem;
    margin-top: 0rem;
    font-weight: 700;
  }

  &__subtitle {
    color: gray;
    text-align: center;
    font-weight: 400;
    font-size: 0.9375rem;
    margin: 0.5rem 0rem;
    margin-top: 0.8rem;
  }

  &__details {
    ul {
      padding: 0rem;
      margin: 0rem;
      list-style: none;
      li {
        margin-top: 1rem;
      }
    }
  }

  &--last {
    margin-bottom: 0px;
  }
}
