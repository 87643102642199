.flex-wrapper {
  padding-left: 18px;
  padding-right: 18px;
  max-width: 1236px;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
  display: flex;

  &__space-between {
    justify-content: space-between;
    align-items: center;
  }

  &__column {
    flex-flow: column;
  }

  &__justify-center {
    justify-content: center;
  }

  &__align-center {
    align-items: center;
  }

  &__right {
    display: flex;
    align-items: center;
  }

  &--medium {
    max-width: 978px;
  }

  .wrapper {
    padding-left: 0;
    padding-right: 0;
  }

  &--no-padding-until-medium {
    padding-left: 0px;
    padding-right: 0px;

    @include atMedium {
      padding-left: 18px;
      padding-right: 18px;
    }
  }

  &--b-margin {
    margin-bottom: 1rem;
    @include atSmall {
      margin-bottom: 3.875rem;
    }
  }
}
