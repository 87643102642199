#root {
  .single-job-pane {
    height: 78vh;
    overflow-y: auto;

    @include atMediumMax {
      position: fixed;
      top: 56px;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: calc(100% - 56px);
      background: white;
      transition: transform 0.3s ease-in;
      transform: translate3d(100%, 0, 0);
    }

    &--open {
      transform: translate3d(0, 0, 0);
      transition: transform 0.3s ease-out;
    }

    &__close {
      position: absolute;
      top: 15px;
      right: 15px;
      background: #fff;

      @include atMedium {
        display: none;
      }
    }
  }
}
