.cart-item {
  margin-left: "100%";
  margin-top: "10%";

  &__container {
    width: 100%;
    object-fit: cover;

    @include atSmallMax {
      display: grid;
      width: 100%;
    }
    @include atMedium {
      display: flex;
      justify-content: space-evenly;
    }
  }

  &__headertext {
    text-align: center;
    margin-inline-start: -7%;

    @include atSmallMax {
      text-align: center;
    }
  }
  &__textfield {
    width: 100%;
    margin-left: 10px;
    margin-top: 3%;
  }

  &__headertext1 {
    text-align: center;
    color: $color-pink-lighter;
    margin-inline-start: -10%;
  }
  &__dahil {
    text-align: center;
    margin-inline-start: -10%;
  }
  &__typographydiv {
    text-align: center;
  }
  &__griditem {
    display: grid;
    grid-template-columns: auto auto auto;
    gap: 110px;

    padding: 10px;
    @include atSmallMax {
      display: grid;
      grid-template-columns: auto;
      gap: 1px;
    }
    @include tablet {
      grid-template-columns: auto;
      gap: 40px;
    }
  }

  &__headergrid {
    display: grid;
    grid-template-columns: auto auto;
    gap: 20px;
  }
  &__headergrid > div {
    padding: 20px 0;
    font-size: 30px;
  }
  &__griditemlog {
    display: grid;
    grid-template-columns: 400px auto;
    gap: 500px;

    @include atSmallMax {
      display: grid;
      grid-template-columns: auto;
      gap: 1px;
    }
    @include tablet {
      grid-template-columns: auto;
      gap: 1px;
    }
  }
  &__griditemlog > div {
    padding: 20px 0;
    font-size: 30px;
  }
  &__griditem > div {
    padding: 20px 0;
    font-size: 30px;
  }
  &__gridcart {
    @include atSmall {
      position: absolute;
      right: 2%;
    }
    @include atLarge {
      position: absolute;
      right: 10%;
    }
  }
  &__security {
    color: green;
    font-size: 0.7em;
  }
  &__gonder {
    @include atSmallMax {
      text-align: match-parent;
      margin-left: 21%;
    }
  }
  &__forgotpassword {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  }
  &__128bit {
    @include atSmallMax {
      margin-left: 44px;
      text-align: center;
    }
    @include atSmall {
      text-align: match-parent;
      margin-left: 21%;
    }
  }
  &__divider {
    display: flex;

    position: absolute;

    margin-top: 10px;
    color: $color-background-darker;

    width: 0px;

    @include tablet {
      display: none;
    }

    @include atSmallMax {
      display: none;
    }
  }
  &__divider1 {
    display: flex;
    height: 350px !important;
    top: inherit;

    position: absolute;

    color: $color-background-darker;

    width: 0px;

    @include tablet {
      display: none;
    }

    @include atSmallMax {
      display: none;
    }
  }
  &__paper-typography {
    margin-inline-start: 10%;
    margin-top: 4px;
  }
  &__paper {
    height: 20%;
    width: 100%;
    margin-top: 14%;
    @include atSmallMax {
      height: 20%;
      width: 100%;
    }
  }
  &__divider1 {
    display: flex;
    height: 350px;

    position: absolute;

    margin-top: 10px;
    color: $color-background-darker;

    width: 0px;

    @include tablet {
      display: none;
    }

    @include atSmallMax {
      display: none;
    }
  }

  &__griditem1 {
    margin-top: 5%;
    margin-right: 5%;
    position: absolute;
    right: 0%;

    @include atSmallMax {
      display: flex;
      position: inherit;
    }
  }

  &__itemwidth {
    width: 100%;
  }
  &__box {
    margin-top: 2%;
  }
  &__box1 {
    margin-top: 10%;
    width: 100%;
  }
  &__box3 {
    margin-top: 24.5%;
    width: 100%;
    margin-left: 13%;
    @include atSmallMax {
      margin-left: 0%;
      margin-top: 2%;
    }

    &__textwrapper {
      display: "flex";
      align-items: "center";
      justify-content: "center";
    }
  }
  &__box4 {
    margin-top: 27.5%;
    width: 100%;

    @include atSmallMax {
      margin-left: 0%;
      margin-top: 2%;
    }
  }
  &__packageform {
    margin-left: 40px;
  }
  &__typographypackage {
    font-size: 1.2em;
  }
}
