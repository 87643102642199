.icon-text {
  text-align: center;
  padding: 20px;
  line-height: 1.5;
  display: flex;
  flex-flow: column;
  align-items: center;
  &__icon {
    border-radius: 50%;
    border: 1px solid gray;
    margin-bottom: 1rem;
    width: 40px;
    height: 40px;
    // text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
}
