.primary-nav {
  ul {
    margin: 0;
    padding: 0;
  }

  li {
    list-style: none;
    float: left;
    @include clearfix;
    padding-left: 20px;
  }

  a {
    color: #fff;
    text-decoration: none;
    font-weight: 300;
  }

  &--pull-right {
    text-align: right;
    float: right;
  }

  &--button {
    margin-left: 10px;
  }
}
