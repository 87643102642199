$color-background: #f2f2f2;

body {
  background-color: $color-background !important;
  margin: 0;
  font-family: "-apple-system", "BlinkMacSystemFont", '"Segoe UI"', "Roboto", '"Helvetica Neue"', "Arial", "sans-serif", '"Apple Color Emoji"', '"Segoe UI Emoji"',
    '"Segoe UI Symbol"', "Ubuntu";
}

div {
  box-sizing: border-box;
}

a {
  color: $color-pink;
  text-decoration: none;
}
