.article-list-item {
  max-width: 200px;

  &__title {
    color: rgb(105, 105, 105);
    font-weight: 600;
    font-size: 15px;
    line-height: 1.6;
  }
}
