.success-page {
  min-height: calc(100vh - 399px);
  text-align: center;
  padding: 2.5rem 2rem;

  &__main-title {
    font-size: 2.7rem;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;

    @include atSmallMax {
      font-size: 2.2rem;
    }
  }

  &__sub-title {
    font-size: 1.3rem;
    font-weight: 400;
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;

    @include atSmallMax {
      font-size: 1.15rem;
    }
  }

  &__paragraph {
    font-size: 1.15rem;
    line-height: 1.5;

    @include atSmallMax {
      font-size: 0.85rem;
      margin-bottom: 1.4rem;
    }
  }

  &__information {
    margin-top: 1rem;
  }
  &__next-step {
    margin-top: 1.5rem;
  }
}
