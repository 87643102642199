.user-menu {
  &__avatar-button {
    cursor: pointer !important;
    margin: 0 !important;
    padding: 0 !important;
    min-width: 52px !important;

    @include atMedium {
      min-width: 64px !important;
      margin: 0 20px 0 6px !important;
    }
  }

  &__avatar {
    margin: 8px 6px;
  }

  &__full-name {
    color: white !important;
    display: none;

    @include atMedium {
      display: inline-block;
    }
  }
}
.css-1hwfws3 {
  //to change react-select styles
  font-size: 17px;
  padding-left: 0 !important;
}

.kep-login-facebook {
  padding: 12px !important;
  font-size: 16px !important;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12) !important;
  border-radius: 3px !important;
}
