.custom-wrapper {
  padding-left: 18px;
  padding-right: 18px;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
  display: flex;
  justify-content: center;

  &--medium {
    max-width: 978px;
  }

  &--small {
    max-width: 770px;
  }
  &__wrappergrid {
    display: grid;
    width: 70vw;
    grid-template-columns: repeat(auto-fill, 400px);
    transform: translateX(-50%);
    justify-content: center;
    gap: 10px;

    @include atLarge {
      display: grid;
      width: 70vw;
      grid-template-columns: repeat(auto-fill, 400px);
      transform: translateX(-50%);
      justify-content: center;
    }
    @include atSmall {
      display: grid;
      width: 80vw;
      grid-template-columns: auto auto;
      justify-content: center;
    }
    @include atSmallMax {
      display: grid;
      width: 80vw;
      grid-template-columns: auto;
      justify-content: center;
    }
    @include atMedium {
      display: grid;
      width: 80vw;
      grid-template-columns: auto auto auto;
      justify-content: center;
    }
  }

  .custom-wrapper {
    padding-left: 0;
    padding-right: 0;
  }

  &--no-padding-until-medium {
    padding-left: 0px;
    padding-right: 0px;

    @include atMedium {
      padding-left: 18px;
      padding-right: 18px;
    }
  }

  &--b-margin {
    margin-bottom: 1 rem;
    @include atSmall {
      margin-bottom: 3.875rem;
    }
  }
}
