.company-card {
  position: relative;
  box-sizing: border-box;
  border: 1px solid #d5d5d5;
  max-width: 180px;
  padding-bottom: 220px;
  margin: 0 auto 24px;
  overflow: hidden;
  cursor: pointer;
  background-color: #fff;

  &__cover-photo {
    position: absolute;
    width: 100%;
    height: 25%;
    left: 0;
    right: 0;
    top: 0;
    background: $color-secondary;
    background: linear-gradient(45deg, rgba(34, 17, 51, 0.7) 0%, rgba(234, 20, 141, 0.7) 100%);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  &__logo {
    position: absolute;
    box-sizing: border-box;
    top: 5%;
    left: 50%;
    transform: translateX(-50%);
    width: 60%;
    height: 44.02%;

    img {
      position: absolute;
      max-width: 100%;
      max-height: 100%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
      padding: 2px;
      background: #fff;
    }
  }

  &__overlay {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.2s linear;
    background-color: rgba(0, 0, 0, 0.05);
  }

  &:hover &__overlay {
    visibility: visible;
    opacity: 1;
  }

  &__name-wrapper {
    position: absolute;
    top: 57%;
    left: 50%;
    transform: translateX(-50%);
    width: 76%;
  }

  &--guest &__name-wrapper {
    top: 65%;
  }

  &__name {
    font-size: 1.2em;
    font-weight: bold;
    @include multiLineEllipsis($lineHeight: 1.2em, $lineCount: 2, $bgColor: #fff);
  }

  &__follow {
    cursor: pointer;
    position: absolute;
    bottom: 4%;
    left: 5%;
    right: 5%;
    width: 90%;
    height: 2.4em;

    background: transparent;
    border: 1px solid $color-secondary;
    color: $color-secondary;
    font-size: 1em;
    padding: 0.4em;
    border-radius: 3px;
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);

    transition: background 0.3s;

    &--following,
    &:hover {
      background: $color-secondary;
      color: white;
    }

    &--following:hover {
      background: $color-secondary-darker;
    }
  }
}
